import { Container, Row, Col } from "react-bootstrap";
import { List, ListItem, Line, BaseChildPage } from "Components";
import { Handshake640 } from "Assets";

const Partnership = () => {
  return (
    <BaseChildPage
      title="Cотрудничество"
      description="Сварка и ремонт промышленного оборудования для производства. Изготовление ферм, конвейерных линий, емкостей. Прокладка магистрали из черного металла и нержавеющей стали высокого давления."
    >
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col xl={6} lg={8}>
              <div className="text-center">
                <h2 className="fw-bold">
                  Мы можем предложить следующие услуги для вашего производства:
                </h2>
                <Line />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6} className="pb-4">
              <List>
                <ListItem number="1">Cварка промышленного оборудования для производства</ListItem>
                <ListItem number="2">Ремонт промышленного оборудования</ListItem>
                <ListItem number="3">Изготовление ферм</ListItem>
                <ListItem number="4">Изготовление емкостей</ListItem>
                <ListItem number="5">Изготовление конвейерных линий</ListItem>
                <ListItem number="6">
                  Прокладка магистрали из черного металла и нержавеющей стали высокого давления
                </ListItem>
                <ListItem number="7">Tокарные работы</ListItem>
                <ListItem number="8">Изготовление кованных изделий</ListItem>
              </List>
            </Col>
            <Col lg={6}>
              <img className="img-fluid" src={Handshake640} alt="Сотрудничество" />
            </Col>
          </Row>
          <Row className="pb-2 pt-4 pt-lg-2">
            <h2 className="text-center fw-bold">Будем рады сотрудничеству!</h2>
          </Row>
        </Container>
      </section>
    </BaseChildPage>
  );
};

export default Partnership;
