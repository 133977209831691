import { Routes, Route } from "react-router-dom";
import * as Pages from "./Pages";

const routes = [
  {
    path: "/",
    element: <Pages.Home />,
  },
  {
    path: "/contacts",
    element: <Pages.Contacts />,
  },
  {
    path: "/services",
    element: <Pages.Services />,
  },
  {
    path: "/partnership",
    element: <Pages.Partnership />,
  },
  {
    path: "/services/fences",
    element: <Pages.Fences />,
  },
  {
    path: "/services/stairs",
    element: <Pages.Stairs />,
  },
  {
    path: "/services/sheds",
    element: <Pages.Sheds />,
  },
  {
    path: "/services/garbage-areas",
    element: <Pages.GarbageAreas />,
  },
  {
    path: "/services/gates",
    element: <Pages.Gates />,
  },
  {
    path: "/services/garden-and-cottage-products",
    element: <Pages.GardenProducts />,
  },
  {
    path: "/services/mebel-loft",
    element: <Pages.MebelLoft />,
  },
  {
    path: "/services/industrial-production",
    element: <Pages.IndustrialProduction />,
  },
  {
    path: "/services/bicycle-parking",
    element: <Pages.BicycleParking />,
  },
  {
    path: "*",
    element: <Pages.NotFound />,
  },
];

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route) => {
        return <Route path={route.path} element={route.element} />;
      })}
    </Routes>
  );
};

export default AppRoutes;
