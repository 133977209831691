import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ViberIcon = (props) => {
  return (
    <a {...props} href="viber://chat?number=%2B375298067909" target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon="fa-stack fa-brands fa-viber" size="lg" color="#665CAC" />
    </a>
  );
};

export default ViberIcon;
