import { default as ServicesChildPage } from "./SevicesChildPage";
import { BicycleParkingImages } from "Assets/Services";

const BicycleParking = () => {
  return (
    <ServicesChildPage
      title="Велопарковки"
      description="Велопарковки: крытые, отдельно стоящие, П-образные, треугольные с навесом. Использование различных материалов для стен: дерево, камень, профнастил, жалюзи и др. 3D проект."
      images={BicycleParkingImages}
      keywords="велопарковка, велопарковка купить, велопарковки нержавеющие, крытая велопарковка, велопарковка навес, велопарковка крытая крыло, велопарковка из стали, велопарковка цена"
    ></ServicesChildPage>
  );
};

export default BicycleParking;
