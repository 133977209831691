import styled from "styled-components";
import { Container, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { default as Logo } from "./Logo";
import { Device } from "Utils";

const SiteNavbar = styled(Navbar)`
  background-color: ${(props) => props.theme.colors.navbar};
`;

const NavLink = styled(Nav.Link)`
  font-size: 18px !important;

  color: ${(props) => props.theme.colors.white} !important;
  text-align: center;

  &.nav-link.active {
    color: ${(props) => props.theme.colors.primaryYellow} !important;
  }

  :hover {
    color: ${(props) => props.theme.colors.primaryYellow} !important;
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      color: ${(props) => props.theme.colors.white} !important;
    }
  }

  @media (min-width: ${Device.desktop}) {
    margin-right: 20px;
  }
`;

const NavigationBar = () => {
  return (
    <SiteNavbar collapseOnSelect expand="lg" className="navigation" variant="dark">
      <Container>
        <Logo />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            <LinkContainer to="/">
              <NavLink>Главная</NavLink>
            </LinkContainer>

            <LinkContainer to="/services">
              <NavLink eventKey={1}>Услуги</NavLink>
            </LinkContainer>

            <LinkContainer to="/partnership">
              <NavLink eventKey={2}>Cотрудничество</NavLink>
            </LinkContainer>

            <LinkContainer to="/contacts">
              <NavLink eventKey={3}>Контакты</NavLink>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </SiteNavbar>
  );
};

export default NavigationBar;
