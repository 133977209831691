import { default as ServicesChildPage } from "./SevicesChildPage";
import { StairsImages } from "Assets/Services";

const Stairs = () => {
  return (
    <ServicesChildPage
      title="Лесницы, перила"
      description="Лестницы и перила любой сложности. Кованые изделия для декора лестниц и перилл. Создание индивидуального проекта."
      images={StairsImages}
      keywords="лестница, купить лестницу, лестница в доме, перила, перила для лестниц, купить перила, кованные перила, перила на крыльце, лестница для бассейна, лестница на 2 этаж, лестница лофт, лофт, лофт брест, перила из нержавейки"
    ></ServicesChildPage>
  );
};

export default Stairs;
