import "./App.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { NavigationBar, Footer } from "Components";
import { ScrollToTop } from "Utils";
import { default as AppRoutes } from "./AppRoutes";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { Theme } from "Utils";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ReactGA from "react-ga4";
ReactGA.initialize("G-PWGQ09XSV0");

library.add(fab, far, fas, faCheckSquare, faCoffee);
const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={Theme}>
        <div className="App">
          <Helmet>
            <meta
              name="description"
              content="Сварочные работы любой сложности в Бресте и Брестской области. Изготовление ворот, металлических решеток, ограждений, козырьков, навесов, лестниц и др. изделий."
            />
            <meta
              name="keywords"
              content="сварщик, сварщик брест, сварочные работы, сварочные работы в бресте, сварочные работы полуавтоматом, сварочные работы цена, услуги сварщика, сварочные работы цена прайс, монтажные работы, строительно-монтажные работы, токарные работы, ковка, ковка брест"
            />
          </Helmet>
          <Router>
            <ScrollToTop />
            <NavigationBar />
            <AppRoutes />
            <Footer className="pt-3" />
          </Router>
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
