import { Container } from "react-bootstrap";
import { PrimaryButton, BaseChildPage } from "Components";

const NotFound = () => {
  return (
    <BaseChildPage
      title="Ошибка 404. Страница не найдена"
      description="Ошибка 404. Страница не найдена"
      hideImage
    >
      <Container>
        <div className="d-flex align-items-center justify-content-center my-3">
          <div className="text-center">
            <h1 className="display-3 fw-bold">Страница не найдена</h1>
            <p className="lead">
              Вы находитесь здесь потому, что запрашиваемая страница не существует или была
              перемещена по другому адресу.
              <br />
              Пожалуйста, воспользуйтесь навигацией, чтобы найти интересующую Вас информацию.
            </p>
            <PrimaryButton href="/">Перейти на главную</PrimaryButton>
          </div>
        </div>
      </Container>
    </BaseChildPage>
  );
};

export default NotFound;
