import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const BicycleParkingImagesHome = {
  home: images["bicycle-parking-home-small.jpg"],
  placeholder: images["bicycle-parking-home-small-placeholder.jpg"],
};

export const BicycleParkingImages = [
  {
    name: "Крытая велопарковка из нержавеющей стали с навесом",
    small: images["bicycle-parking-3-small.jpg"],
    high: images["bicycle-parking-3-high.jpg"],
    width: "1280",
    height: "720",
  },
  {
    name: "Крытая велопарковка из профнастила и поликарбоната",
    small: images["bicycle-parking-1-small.jpg"],
    high: images["bicycle-parking-1-high.jpg"],
    width: "1366",
    height: "768",
  },
  {
    name: "Крытая велопарковка из профнастила и поликарбоната",
    small: images["bicycle-parking-2-small.jpg"],
    high: images["bicycle-parking-2-high.jpg"],
    width: "1280",
    height: "720",
  },
];
