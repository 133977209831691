import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const IndustrialProductionHome = {
  home: images["industrial-production-2-small.jpg"],
  placeholder: images["industrial-production-2-small-placeholder.jpg"],
};

export const IndustrialProductionImages = [
  {
    name: "Стык из нержавеющей стали",
    small: images["industrial-production-2-small.jpg"],
    high: images["industrial-production-2-high.jpg"],
  },
  {
    name: "Подставка под конвейерную линию",
    small: images["industrial-production-3-small.jpg"],
    high: images["industrial-production-3-high.jpg"],
    width: "1280",
    height: "720",
  },
  {
    name: "Подставка подконвейерную линию",
    small: images["industrial-production-4-small.jpg"],
    high: images["industrial-production-4-high.jpg"],
    width: "1280",
    height: "720",
  },
  {
    name: "Автоматическая торцовочная линия",
    small: images["industrial-production-6-small.jpg"],
    high: images["industrial-production-6-high.jpg"],
    width: "1280",
    height: "720",
  },
  {
    name: "Автоматическая торцовочная линия",
    small: images["industrial-production-7-small.jpg"],
    high: images["industrial-production-7-high.jpg"],
  },
  {
    name: "Ферма",
    small: images["industrial-production-8-small.jpg"],
    high: images["industrial-production-8-high.jpg"],
  },
  {
    name: "Дефлектор",
    small: images["industrial-production-1-small.jpg"],
    high: images["industrial-production-1-high.jpg"],
    width: "1366",
    height: "768",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-15-small.jpg"],
    high: images["industrial-production-15-high.jpg"],
    width: "1280",
    height: "720",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-12-small.jpg"],
    high: images["industrial-production-12-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-13-small.jpg"],
    high: images["industrial-production-13-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-14-small.jpg"],
    high: images["industrial-production-14-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-16-small.jpg"],
    high: images["industrial-production-16-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-17-small.jpg"],
    high: images["industrial-production-17-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-18-small.jpg"],
    high: images["industrial-production-18-high.jpg"],
    width: "1600",
    height: "1197",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-19-small.jpg"],
    high: images["industrial-production-19-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Блок-мудуль электростанции",
    small: images["industrial-production-20-small.jpg"],
    high: images["industrial-production-20-high.jpg"],
    width: "1280",
    height: "960",
  },
  {
    name: "Стелажи под бухты кабеля",
    small: images["industrial-production-9-small.jpg"],
    high: images["industrial-production-9-high.jpg"],
    width: "957",
    height: "1280",
  },
  {
    name: "Тележка для магазина",
    small: images["industrial-production-10-small.jpg"],
    high: images["industrial-production-10-high.jpg"],
    width: "960",
    height: "1280",
  },
  {
    name: "Тележка для магазина",
    small: images["industrial-production-11-small.jpg"],
    high: images["industrial-production-11-high.jpg"],
    width: "960",
    height: "1280",
  },
];
