import { default as ServicesChildPage } from "./SevicesChildPage";
import { GarbageAreasImages } from "Assets/Services";

const GarbageAreas = () => {
  return (
    <ServicesChildPage
      title="Крытые мусорные площадки"
      description="Крытые мусорные площадки открытого и закрытого типа, установка кодового замка. Различные материалы для стен площадки: дерево, камень, профнастил, жалюзи и др. 3D проект."
      images={GarbageAreasImages}
      keywords="мусорные площадки, закрытые мусорные площадки"
    ></ServicesChildPage>
  );
};

export default GarbageAreas;
