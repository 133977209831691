import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const GardenProductsHome = {
  home: images["garden-product-home-small.jpg"],
  placeholder: images["garden-product-home-small-placeholder.jpg"],
};

export const GardenProductsImages = [
  {
    name: "Мангал",
    small: images["garden-product-2-small.jpg"],
    high: images["garden-product-2-high.jpg"],
  },
  {
    name: "Мангал",
    small: images["garden-product-1-small.jpg"],
    high: images["garden-product-1-high.jpg"],
  },
  {
    name: "Лавочка",
    small: images["garden-product-3-small.jpg"],
    high: images["garden-product-3-high.jpg"],
    width: "1065",
    height: "1283",
  },
];
