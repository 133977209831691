import { Device, useMediaQuery } from "Utils";
import { default as HeaderSection } from "./HeaderSection";
import { default as AboutUsSection } from "./AboutUsSection";
import { default as BenefitsSection } from "./BenefitsSection";
import { default as WorkOrderSection } from "./WorkOrderSection";
import { BasePage } from "Components";

const HomePage = () => {
  const isMobile = useMediaQuery(`(max-width: ${Device.mobile})`);
  return (
    <BasePage title="Главная">
      <HeaderSection isMobile={isMobile} />
      <AboutUsSection isMobile={isMobile} />
      <BenefitsSection />
      <WorkOrderSection />
    </BasePage>
  );
};

export default HomePage;
