import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const GarbageAreasImagesHome = {
  home: images["garbage-area-2-small.jpg"],
  placeholder: images["garbage-area-2-small-placeholder.jpg"],
};

export const GarbageAreasImages = [
  {
    name: "Крытая мусорная площадка с жалюзи",
    small: images["garbage-area-2-small.jpg"],
    high: images["garbage-area-2-high.jpg"],
    width: "1024",
    height: "576",
  },
  {
    name: "Крытая мусорная площадка с жалюзи и демлерным блоком",
    small: images["garbage-area-1-small.jpg"],
    high: images["garbage-area-1-high.jpg"],
    width: "960",
    height: "540",
  },
];
