import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Container, Row, Col } from "react-bootstrap";

const smallItemStyles = {
  cursor: "pointer",
  objectFit: "cover",
  width: "100%",
  maxHeight: "100%",
};

const ServiceGallery = (props) => {
  return (
    <Gallery>
      <Container>
        <Row>
          {props.images.map((image, index) => (
            <Col sm={6} md={3} lg={3} key={index} className="mb-2 h-100">
              <Item
                key={index}
                original={image.original}
                thumbnail={image.thumbnail}
                width={image.width}
                height={image.height}
                alt={image.alt}
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src={image.src}
                    className="img-fluid"
                    ref={ref}
                    alt={image.alt}
                    onClick={open}
                  />
                )}
              </Item>
            </Col>
          ))}
        </Row>
      </Container>
    </Gallery>
  );
};

export default ServiceGallery;
