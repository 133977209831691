import React from "react";
import { Helmet } from "react-helmet-async";

const BasePage = (props) => {
  const href = window.location.href;
  const documentTitle = `${props.title} - Сварочные работы в Бресте и Брестской области`;
  const description = props.description;
  const keywords = props.keywords;
  return (
    <div>
      <Helmet>
        <title>{documentTitle}</title>
        <link rel="canonical" href={href} />
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
      {props.children}
    </div>
  );
};

export default BasePage;
