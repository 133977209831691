import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CircledIcon = styled.i.attrs({
  className: "rounded-circle",
})`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  float: left;
  font-size: 22px;
`;

const List = (props) => {
  return <div {...props}>{props.children}</div>;
};

const ListItem = (props) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="flex-shrink-0">
        <CircledIcon>
          <FontAwesomeIcon icon={`fa-solid fa-${props.number}`} />
        </CircledIcon>
      </div>
      <div className="flex-grow-1 ms-3">{props.children}</div>
    </div>
  );
};

export { List, ListItem };
