import { default as ServicesChildPage } from "./SevicesChildPage";
import { ShedsImages } from "Assets/Services";

const Sheds = () => {
  return (
    <ServicesChildPage
      title="Навесы"
      description="Изготовление, монтаж и покраска навесов любой сложности. Навесы для авто. Использование материала на выбор заказчика: поликарбонат, профнастил, металлочерепица."
      keywords="навес, навесы над крыльцом, навесы, навесы для машин, навесы из поликарбоната, навесы к дому, навесы беседки, навес к дому, навес из поликарбоната цена, навес цена"
      images={ShedsImages}
    ></ServicesChildPage>
  );
};

export default Sheds;
