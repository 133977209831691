import { Container, Row, Col } from "react-bootstrap";
import { BenefitCard, Line } from "Components";

const BenefitsSection = () => {
  return (
    <section className="services" id="benefits">
      <Container>
        <Row className="justify-content-center">
          <Col xl={6} lg={8}>
            <div className="title text-center">
              <h2 className="fw-bold">Наши преимущества</h2>
              <div>
                Наше производство идет по пути развития и усовершенствования, что позволяет нам
                делать заказы, которые будут радовать наших клиентов.
                <br /> Преимущества выбора нас – это выгода для каждого клиента!
                <Line />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={6} className="mb-4 mb-lg-0">
            <BenefitCard theme="dark" title="End-to-end решение" icon="fa-solid fa-wrench">
              Вся работа по воплощению вашего заказа полностью реализуется нами от проектирования до
              производства, доставки и установки. Вы можете быть уверены, что получите готовое,
              качественно выполненное изделие из «одних рук»!
            </BenefitCard>
          </Col>
          <Col lg={4} sm={6} className="mb-4 mb-lg-0">
            <BenefitCard title="Собственное производство" icon="fa-regular fa-rectangle-list">
              Собственное производство позволяет нам оставлять цены приятными для клиентов, а также
              решать логистические задачи.
            </BenefitCard>
          </Col>
          <Col lg={4} sm={6} className="mb-4 mb-lg-0">
            <BenefitCard theme="dark" title="Гарантия качества" icon="fa-regular fa-thumbs-up">
              Мы гарантируем быстрое и качественное выполнение заказа. Соблюдение гарантийных
              обязательств – одна из основных задач нашей работы.
            </BenefitCard>
          </Col>
          <Col lg={4} sm={6} className="mb-4 mb-lg-0">
            <BenefitCard title="Чистота в работе" icon="fa-regular fa-circle-check">
              Официальное оформление договора. Наш клиент всегда может посмотреть и проверить
              порядок выполнения заказа.
            </BenefitCard>
          </Col>
          <Col lg={4} sm={6} className="mb-4 mb-lg-0">
            <BenefitCard
              theme="dark"
              title="Гибкость в условиях работы"
              icon="fa-regular fa-handshake"
            >
              Мы уважаем и учитываем пожелания наших заказчиков, помогаем подбирать оптимальные
              конструкторские решения для заказа, учитываем финансовое положение каждого клиента,
              согласовываем удобные сроки изготовления заказа.
            </BenefitCard>
          </Col>
          <Col lg={4} sm={6} className="mb-4 mb-lg-0">
            <BenefitCard title="Вариативность способа оплаты" icon="fa-regular fa-credit-card">
              <div className="text-start">
                <ul>
                  <li>Наличный/безналичный расчет</li>
                  <li>Возможность оплаты частями</li>
                  <li>
                    Возможность оплаты с применением льготных банковских кредитов и материнского
                    капитала
                  </li>
                </ul>
              </div>
            </BenefitCard>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BenefitsSection;
