import styled from "styled-components";

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors[props.color]};
  text-decoration: none;

  /* don't need to hide hover */
  :hover {
    color: ${(props) => props.theme.colors[props.color]};
  }
`;

const Link = (props) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};

export default Link;
