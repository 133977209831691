import styled from "styled-components";

const Block = styled.div`
  height: 2px;
  margin: 20px auto 20px;
  position: relative;
  width: 80px;
  background: ${(props) => props.theme.colors.primary};
`;

const Line = (props) => {
  return (
    <Block {...props} className="border">
      {props.children}
    </Block>
  );
};

export default Line;
