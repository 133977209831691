import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const GatesImagesHome = {
  home: images["gate-home-small.jpg"],
  placeholder: images["gate-home-small-placeholder.jpg"],
};

export const GatesImages = [
  {
    name: "Распашные ворота с коваными элементами",
    small: images["gate-8-small.jpg"],
    high: images["gate-8-high.jpg"],
  },
  {
    name: "Откатные ворота-ранчо",
    small: images["gate-1-small.jpg"],
    high: images["gate-1-high.jpg"],
  },
  {
    name: "Распашные ворота с жалюзи",
    small: images["gate-3-small.jpg"],
    high: images["gate-3-high.jpg"],
  },
  {
    name: "Откатные ворота с металлосайдингом",
    small: images["gate-5-small.jpg"],
    high: images["gate-5-high.jpg"],
    width: "1552",
    height: "873",
  },
  {
    name: "Откатные ворота с металлосайдингом",
    small: images["gate-6-small.jpg"],
    high: images["gate-6-high.jpg"],
    width: "1366",
    height: "768",
  },
  {
    name: "Откатные ворота с металлосайдингом",
    small: images["gate-7-small.jpg"],
    high: images["gate-7-high.jpg"],
  },
  {
    name: "Кованые ворота",
    small: images["gate-2-small.jpg"],
    high: images["gate-2-high.jpg"],
  },
  {
    name: "Решётки из металла",
    small: images["gate-4-small.jpg"],
    high: images["gate-4-high.jpg"],
  },
  {
    name: "Ворота",
    small: images["gate-9-small.jpg"],
    high: images["gate-9-high.jpg"],
  },
  {
    name: "Ворота",
    small: images["gate-10-small.jpg"],
    high: images["gate-10-high.jpg"],
  },
  {
    name: "Ворота",
    small: images["gate-11-small.jpg"],
    high: images["gate-11-high.jpg"],
  },
  {
    name: "Ворота",
    small: images["gate-12-small.jpg"],
    high: images["gate-12-high.jpg"],
  },
  {
    name: "Калитка",
    small: images["gate-13-small.jpg"],
    high: images["gate-13-high.jpg"],
    width: "864",
    height: "1061",
  },
];
