import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const StairsImagesHome = {
  home: images["stair-7-small.jpg"],
  placeholder: images["stair-7-small-placeholder.jpg"],
};

export const StairsImages = [
  {
    name: "Лестница в стиле Loft",
    small: images["stair-8-small.jpg"],
    high: images["stair-8-high.jpg"],
    width: "900",
    height: "1200",
  },
  {
    name: "Лестница c кованными элементами из нержавеющей стали",
    small: images["stair-9-small.jpg"],
    high: images["stair-9-high.jpg"],
    width: "1197",
    height: "1600",
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-10-small.jpg"],
    high: images["stair-10-high.jpg"],
    width: "1197",
    height: "1600",
  },
  {
    name: "Лестница c кованными элементами",
    small: images["stair-11-small.jpg"],
    high: images["stair-11-high.jpg"],
    width: "1197",
    height: "1600",
  },
  {
    name: "Лестница в бассейн",
    small: images["stair-1-small.jpg"],
    high: images["stair-1-high.jpg"],
    width: "1197",
    height: "1600",
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-2-small.jpg"],
    high: images["stair-2-high.jpg"],
    width: "1197",
    height: "1600",
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-5-small.jpg"],
    high: images["stair-5-high.jpg"],
    width: "897",
    height: "1200",
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-6-small.jpg"],
    high: images["stair-6-high.jpg"],
    width: "897",
    height: "1200",
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-7-small.jpg"],
    high: images["stair-7-high.jpg"],
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-3-small.jpg"],
    high: images["stair-3-high.jpg"],
  },
  {
    name: "Лестница в стиле Loft",
    small: images["stair-4-small.jpg"],
    high: images["stair-4-high.jpg"],
  },
  {
    name: "Перила в стиле Loft",
    small: images["stair-12-small.jpg"],
    high: images["stair-12-high.jpg"],
  },
  {
    name: "Перила в стиле Loft",
    small: images["stair-15-small.jpg"],
    high: images["stair-15-high.jpg"],
  },
  {
    name: "Перила в стиле Loft",
    small: images["stair-13-small.jpg"],
    high: images["stair-13-high.jpg"],
  },
  {
    name: "Перила в стиле Loft",
    small: images["stair-14-small.jpg"],
    high: images["stair-14-high.jpg"],
  },
  {
    name: "Перила в стиле Loft",
    small: images["stair-16-small.jpg"],
    high: images["stair-16-high.jpg"],
  },
  {
    name: "Балконные перила в стиле Loft c кованными элементами",
    small: images["stair-17-small.jpg"],
    high: images["stair-17-high.jpg"],
  },
  {
    name: "Лестница и перила",
    small: images["stair-19-small.jpg"],
    high: images["stair-19-high.jpg"],
    width: "1087",
    height: "960",
  },
  {
    name: "Лестница и перила",
    small: images["stair-18-small.jpg"],
    high: images["stair-18-high.jpg"],
    width: "690",
    height: "960",
  },
];
