import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Device, useMediaQuery, Theme } from "Utils";

var themes = {
  light: {
    name: "light",
    textColor: Theme.colors.black,
    iconColor: Theme.colors.primary,
    cardColor: Theme.colors.white,
  },
  dark: {
    name: "dark",
    textColor: Theme.colors.white,
    iconColor: Theme.colors.white,
    cardColor: Theme.colors.primary,
  },
};

const BenefitCardIconBlock = styled.div`
  margin-bottom: 15px;
`;

const BenefitCardIcon = styled.i`
  font-size: 40px;
  color: ${(props) => props.theme.iconColor};
`;

const BenefitCardTitle = styled.h5`
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
  font-weight: bold;
`;

const BenefitCardContent = styled.div`
  color: ${(props) => props.theme.textColor};
`;

const BenefitsCardBlock = styled.div`
  height: 100%;
  ${(props) => `
      background: ${props.theme.cardColor};
      color: ${props.theme.textColor};`}
`;

const BenefitCard = (props) => {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const isTablet = useMediaQuery(`(max-width: ${Device.tablet})`);
  const cardTheme = props.theme === themes.dark.name ? themes.dark : themes.light;
  return (
    <BenefitsCardBlock
      theme={cardTheme}
      className="p-4 text-center"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <BenefitCardIconBlock className="text-center">
        <BenefitCardIcon theme={cardTheme}>
          <FontAwesomeIcon
            icon={props.icon}
            className={hovered && !isTablet ? "fa-beat-fade" : ""}
          />
        </BenefitCardIcon>
      </BenefitCardIconBlock>
      <BenefitCardTitle theme={cardTheme}>{props.title}</BenefitCardTitle>
      <BenefitCardContent theme={cardTheme}>{props.children}</BenefitCardContent>
    </BenefitsCardBlock>
  );
};

export default BenefitCard;
