import styled from "styled-components";
import { Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Device } from "Utils";

const TitleLink = styled.a`
  text-decoration: none;
  outline: none;
  color: ${(props) => props.theme.colors.black};

  :hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }

  @media (hover: none), (hover: on-demand) {
    text-decoration: underline;
  }

  @media (max-width: ${Device.tablet}) {
    text-decoration: underline;
  }
`;

const ServiceCard = styled.div`
  border: none;
`;

const ServiceCardImageBox = styled.div`
  overflow: hidden;
`;

const CardImageWithoutHover = styled.img`
  width: 100%;
  height: 30vh;
  object-fit: fill;
`;

const ServiceCardImage = styled(LazyLoadImage)`
  width: 100%;

  height: 30vh;
  max-height: 320px;
  object-fit: fill;

  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;

  @media (hover: hover) {
    :hover {
      opacity: 0.7;
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      -ms-transform: scale(1.15);
      -o-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
`;

const ServiceCardTitle = styled.h4`
  font-weight: bold;
`;

const Card = (props) => {
  const placeHolderImage = props.placeholder;
  return (
    <Col sm={6} md={6} lg={4} className="mb-4">
      <ServiceCard className="card h-100">
        <ServiceCardImageBox className="shadow">
          {props.href ? (
            <a href={props.href}>
              <ServiceCardImage
                className="card-img-top"
                src={props.imgSrc}
                alt={props.title}
                effect="blur"
                placeholderSrc={placeHolderImage}
              />
            </a>
          ) : (
            <CardImageWithoutHover
              className="card-img-top"
              src={props.imgSrc}
              alt={props.title}
              effect="blur"
              placeholderSrc={placeHolderImage}
            />
          )}
        </ServiceCardImageBox>
        <div className="card-body">
          <ServiceCardTitle className="card-title text-center text-uppercase">
            {props.href ? <TitleLink href={props.href}>{props.title}</TitleLink> : props.title}
          </ServiceCardTitle>
          <div className="card-text">{props.children}</div>
        </div>
      </ServiceCard>
    </Col>
  );
};

export default Card;
