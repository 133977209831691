import { Container, Row, Col } from "react-bootstrap";
import { Line, List, ListItem } from "Components";
import { WorkOrder } from "Assets";

const WorkOrderSection = () => {
  return (
    <section id="work_order" className="pt-1 pt-sm-5 pb-4">
      <Container>
        <Row className="justify-content-center">
          <Col xl={6} lg={8}>
            <div className="title text-center">
              <h2 className="fw-bold">Порядок выполнения работ</h2>
              <Line />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-around align-items-center">
          <Col lg={6}>
            <List>
              <ListItem number="1">Вам нужна наша помощь – и вы звоните нам!</ListItem>
              <ListItem number="2">
                Этап замеров, консультации, согласования цены и сроков исполнения заказа.
              </ListItem>
              <ListItem number="3">
                Оформление и подписание договора, внесение предоплаты. Cумма предоплаты фиксируется
                в договоре.
              </ListItem>
              <ListItem number="4">
                Этап выполнения заказа, доставка и монтаж (при необходимости).
              </ListItem>
              <ListItem number="5">
                Подписание акта о приемке выполненных работ заказчиком.
              </ListItem>
              <ListItem number="6">Окончательная оплата заказа.</ListItem>
              <ListItem number="7">Соблюдение гарантийных обязательств.</ListItem>
              <ListItem number="8">При наличии нового заказа вы опять звоните нам!</ListItem>
            </List>
          </Col>
          <Col lg={5} className="pt-4 pt-lg-0">
            <img className="img-fluid" src={WorkOrder} alt="Порядок выполнения работ" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WorkOrderSection;
