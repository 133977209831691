import { Container, Row } from "react-bootstrap";
import { BaseChildPage } from "Components";
import { default as Card } from "./Card";
import {
  BicycleParkingImagesHome,
  FencesImagesHome,
  StairsImagesHome,
  ShedsImagesHome,
  TurningWorkImagesHome,
  ForgingImagesHome,
  GarbageAreasImagesHome,
  GatesImagesHome,
  GardenProductsHome,
  MebelLoftImagesHome,
  IndustrialProductionHome,
  OthersImagesHome,
} from "Assets/Services";

const Services = () => {
  return (
    <BaseChildPage title="Услуги">
      <Container>
        <Row>
          <Card
            href="/services/fences"
            title="Заборы, ограждения"
            imgSrc={FencesImagesHome.home}
            placeholder={FencesImagesHome.placeholder}
          >
            <ul>
              <li>
                Изготовление, монтаж и покраска:
                <ul>
                  <li>заборов любой сложности</li>
                  <li>газонных ограждений</li>
                  <li>кованных заборов</li>
                </ul>
              </li>
              <li>Монтаж 3D заборов </li>
              <li>Создание индивидуального проекта</li>
            </ul>
          </Card>
          <Card
            href="/services/stairs"
            title="Лестницы, перилла"
            imgSrc={StairsImagesHome.home}
            placeholder={StairsImagesHome.placeholder}
          >
            <ul>
              <li>
                Изготовление, монтаж и покраска:
                <ul>
                  <li>лестниц и перил любой сложности</li>
                  <li>кованных изделий для декора лестниц и перилл</li>
                </ul>
              </li>
              <li>Создание индивидуального проекта </li>
            </ul>
          </Card>
          <Card
            href="/services/sheds"
            title="Навесы"
            imgSrc={ShedsImagesHome.home}
            placeholder={ShedsImagesHome.placeholder}
          >
            <ul>
              <li>
                Изготовление, монтаж и покраска:
                <ul>
                  <li>навесов любой сложности</li>
                  <li>навесов для авто</li>
                </ul>
              </li>
              <li>
                Использование материала на выбор заказчика: поликарбонат, профнастил,
                металлочерепица
              </li>
            </ul>
          </Card>
          <Card
            href="/services/garbage-areas"
            title="Крытые мусорные площадки"
            imgSrc={GarbageAreasImagesHome.home}
            placeholder={GarbageAreasImagesHome.placeholder}
          >
            <ul>
              <li>Изготовление мусорных площадок открытого и закрытого типа «под ключ»</li>
              <li>Возможность установки кодового замка</li>
              <li>
                Использование различных материалов для стен площадки: дерево, камень, профнастил,
                жалюзи и др.
              </li>
              <li>Создание 3D проекта</li>
            </ul>
          </Card>
          <Card
            href="/services/gates"
            title="Ворота, калитки"
            imgSrc={GatesImagesHome.home}
            placeholder={GatesImagesHome.placeholder}
          >
            <ul>
              <li>
                Изготовление, монтаж и покраска всех видов ворот:
                <ul>
                  <li>распашные</li>
                  <li>откатные</li>
                  <li>гаражные</li>
                  <li>кованные</li>
                </ul>
              </li>
              <li>Изготовление, монтаж и покраска калиток любой сложности </li>
              <li>
                Использование любого материала: профильная труба, профнастил, металлический
                штакетник и др.
              </li>
            </ul>
          </Card>
          <Card
            href="/services/bicycle-parking"
            title="Велопарковки"
            imgSrc={BicycleParkingImagesHome.home}
            placeholder={BicycleParkingImagesHome.placeholder}
          >
            <ul>
              <li>
                Изготовление велопарковок «под ключ»:
                <ul>
                  <li>крытые</li>
                  <li>отдельно стоящие</li>
                  <li>П-образные</li>
                  <li>треугольные</li>
                  <li>с навесом</li>
                </ul>
              </li>
              <li>
                Использование различных материалов для стен: дерево, камень, профнастил, жалюзи и
                др.
              </li>
              <li>Создание 3D проекта</li>
            </ul>
          </Card>
          <Card
            href="/services/mebel-loft"
            title="Мебель в стиле Loft"
            imgSrc={MebelLoftImagesHome.home}
            placeholder={MebelLoftImagesHome.placeholder}
          >
            <ul>
              <li>
                Изготовление, монтаж и покраска мебели в стиле LOFT:
                <ul>
                  <li>столы и обеденные группы</li>
                  <li>стеллажи и полки для производства и для дома</li>
                  <li>вешалки для магазина одежды</li>
                  <li>кровати с кованными элементами</li>
                </ul>
              </li>
            </ul>
          </Card>
          <Card
            href="services/garden-and-cottage-products"
            title="Изделия для сада и дачи"
            imgSrc={GardenProductsHome.home}
            placeholder={GardenProductsHome.placeholder}
          >
            <ul>
              <li>
                Изготовление, монтаж и покраска изделий для сада и дачи:
                <ul>
                  <li>мангалы</li>
                  <li>гамаки</li>
                  <li>садовые качели</li>
                  <li>беседки</li>
                  <li>лавочки</li>
                </ul>
              </li>
            </ul>
          </Card>
          <Card
            href="/services/industrial-production"
            title="Изделия для промышленного производства"
            imgSrc={IndustrialProductionHome.home}
            placeholder={IndustrialProductionHome.placeholder}
          >
            <ul>
              <li>Сварка промышленного оборудования для производства</li>
              <li>Ремонт промышленного оборудования</li>
              <li>Изготовление ферм, конвейерных линий, емкостей</li>
              <li>Прокладка магистрали из черного металла и нержавеющей стали высокого давления</li>
              <li>Токарные работы</li>
              <li>Изготовление кованых изделий</li>
            </ul>
          </Card>
          <Card
            title="Токарные работы"
            imgSrc={TurningWorkImagesHome.home}
            placeholder={TurningWorkImagesHome.placeholder}
          >
            <ul>
              <li>Выполнение токарных работ по чертежам заказчика</li>
              <li>Создание индивидуального проекта</li>
            </ul>
          </Card>
          <Card
            title="Ковка"
            imgSrc={ForgingImagesHome.home}
            placeholder={ForgingImagesHome.placeholder}
          >
            <ul>
              <li>Изготовление кованных изделий по чертежам заказчика</li>
              <li>Создание индивидуального проекта</li>
            </ul>
          </Card>
          <Card
            title="Прочие услуги"
            imgSrc={OthersImagesHome.home}
            placeholder={OthersImagesHome.placeholder}
          >
            <ul>
              <li>Изготовление декоративных изделий</li>
              <li>Изготовление изделий любой сложности по чертежам заказчика</li>
            </ul>
          </Card>
        </Row>
      </Container>
    </BaseChildPage>
  );
};

export default Services;
