const Colors = {
  primaryYellow: "#ffc107",
  primary: "#1a555c",
  white: "white",
  black: "#212529",
  navbar: "#2e2c2e",
  socialIcon: "#545454",
  grey: "#f6f6f6",
};

const Theme = {
  colors: Colors,
};

export default Theme;
