import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import { Device } from "Utils";
import { PrimaryButton } from "Components";
import { Home1920, Home1220, Home640, Home920 } from "Assets";

const Header = styled.header`
  height: 45vh;
  background-image: url(${Home1920});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
  background-color: ${(props) => props.theme.colors.navbar};

  @media (max-width: ${Device.desktopL}) {
    background-image: url(${Home1220});
  }

  @media (max-width: ${Device.desktop}) {
    background-image: url(${Home920});
  }

  @media (max-width: ${Device.tablet}) {
    background-image: url(${Home640});
  }

  @media (min-width: ${Device.mobile}) {
    height: 65vh;
  }
`;

const HeaderTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};

  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  font-size: 26px;
  line-height: 26px;

  .br-1,
  .br-3 {
    display: initial;
  }

  @media (max-width: ${Device.mobileS}) {
    font-size: 22px;
    line-height: 22px;
  }

  @media (min-width: ${Device.mobile}) {
    font-size: 31px;
    line-height: 31px;
    .br-1,
    .br-3 {
      display: initial;
    }
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 31px;
    line-height: 1.4;
    .br-1,
    .br-3 {
      display: none;
    }
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 42px;

    .br-1,
    .br-3 {
      display: none;
    }
  }

  @media (min-width: ${Device.desktopL}) {
    font-size: 50px;

    .br-1,
    .br-3 {
      display: none;
    }
  }

  @media (min-width: ${Device.desktopXL}) {
    font-size: 56px;

    .br-1,
    .br-3 {
      display: none;
    }
  }
`;

const HeaderSubTitle = styled.p`
  color: ${(props) => props.theme.colors.white};
  line-height: 1.4;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  font-size: 26px;
  line-height: 26px;
  font-family: "Caveat", Arial;
  font-style: cursive;
  font-weight: bold;

  @media (max-width: ${Device.mobileS}) {
    font-size: 22px;
    line-height: 22px;
  }

  @media (min-width: ${Device.mobile}) {
    font-size: 31px;
    line-height: 31px;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 31px;
    line-height: 31px;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 42px;
    line-height: 42px;
  }

  @media (min-width: ${Device.desktopL}) {
    font-size: 50px;
    line-height: 50px;
  }

  @media (min-width: ${Device.desktopXL}) {
    font-size: 56px;
    line-height: 56px;
  }
`;

const HeaderSection = (props) => {
  return (
    <Header>
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <div className="col-12 text-center">
            <HeaderTitle>
              Cварочные работы <br className="br-1" /> любой сложности
              <br className="br-2" />в Бресте <br className="br-3" /> и Брестской области
            </HeaderTitle>
            <HeaderSubTitle className="text-center">От вас идея – от нас результат!</HeaderSubTitle>
            {props.isMobile ? (
              <PrimaryButton href="tel:+375298067909">Связаться с нами</PrimaryButton>
            ) : (
              <PrimaryButton className="mt-3" href="/services">
                Узнать больше
              </PrimaryButton>
            )}
          </div>
        </Row>
      </Container>
    </Header>
  );
};

export default HeaderSection;
