import styled from "styled-components";

const Button = styled.a`
  color: ${(props) => props.theme.colors.white};

  :hover {
    color: ${(props) => props.theme.colors.primaryYellow};
    background-color: transparent;
  }
`;

const PrimaryButton = (props) => {
  return (
    <Button {...props} className={`btn btn-lg btn-warning text-uppercase ${props.className}`}>
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
