import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { default as BasePage } from "./BasePage";
import { Device } from "Utils";
import { Home1920, Home1220, Home640, Home920 } from "Assets";

const Header = styled.header`
  height: 15vh;
  background-image: url(${Home1920});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  background-color: ${(props) => props.theme.colors.navbar};

  @media (max-width: ${Device.desktopL}) {
    background-image: url(${Home1220});
  }

  @media (max-width: ${Device.desktop}) {
    background-image: url(${Home920});
  }

  @media (max-width: ${Device.tablet}) {
    background-image: url(${Home640});
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
`;

const BaseChildPage = (props) => {
  return (
    <BasePage title={props.title} keywords={props.keywords} description={props.description}>
      {!props.hideImage && (
        <Header>
          <Container className="h-100">
            <Row className="h-100 align-items-center">
              <Col className="col-12 text-center">
                <Title className="h2">{props.title}</Title>
              </Col>
            </Row>
          </Container>
        </Header>
      )}
      {props.children}
    </BasePage>
  );
};

export default BaseChildPage;
