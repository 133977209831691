import { importAllImages } from "Utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const ShedsImagesHome = {
  home: images["shed-home-small.jpg"],
  placeholder: images["shed-home-small-placeholder.jpg"],
};

export const ShedsImages = [
  {
    name: "Навес из металла с поликарбонатом",
    small: images["shed-2-small.jpg"],
    high: images["shed-2-high.jpg"],
    width: "1397",
    height: "786",
  },
  {
    name: "Навес из металла с поликарбонатом",
    small: images["shed-3-small.jpg"],
    high: images["shed-3-high.jpg"],
    width: "1076",
    height: "606",
  },
  {
    name: "Навес из металла с поликарбонатом",
    small: images["shed-4-small.jpg"],
    high: images["shed-4-high.jpg"],
    width: "1280",
    height: "720",
  },
  {
    name: "Навес из металла с профнастилом",
    small: images["shed-5-small.jpg"],
    high: images["shed-5-high.jpg"],
    width: "1041",
    height: "585",
  },
  {
    name: "Навес из металла с профнастилом",
    small: images["shed-6-small.jpg"],
    high: images["shed-6-high.jpg"],
    width: "1076",
    height: "606",
  },
  {
    name: "Навес из металла с поликарбонатом",
    small: images["shed-7-small.jpg"],
    high: images["shed-7-high.jpg"],
  },
  {
    name: "Навес из металла для хранения пиломатериалов",
    small: images["shed-1-small.jpg"],
    high: images["shed-1-high.jpg"],
    width: "1484",
    height: "833",
  },
];
