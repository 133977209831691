import { BaseChildPage, ServiceGallery } from "Components";

const ServicesChildPage = (props) => {
  const images = props.images.map((item) => {
    return {
      original: item.high,
      thumbnail: item.small,
      alt: item.name,
      src: item.small,
      width: item.width ?? "1600",
      height: item.height ?? "900",
    };
  });

  return (
    <BaseChildPage {...props}>
      <ServiceGallery images={images}></ServiceGallery>
    </BaseChildPage>
  );
};

export default ServicesChildPage;
